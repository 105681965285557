<template src="./usp.html"></template>

<script>
import Http from '@/shared/http-config';
import { Sortable } from 'sortablejs';

const createSortable = (el, options, vnode) => {
  return Sortable.create(el, {
    ...options
  });
};
const sortable = {
  name: 'sortable',
  bind(el, binding, vnode) {
    const table = el.querySelector('table');
    if(table !== null){
      table._sortable = createSortable(table.querySelector('tbody'), binding.value, vnode);
    }
  }
};

export default {
  name: "Usp",
  directives: {sortable},
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  data: function () {
    return {
      types: {
        1: {
          value: 1,
          name: 'Merkmale',
          func: this.loadUsp1,
          id: 'loadUsp1',
          meta: {
            per_page: 9
          },
          usp: [],
          sortableOptions: {
            ghostClass: 'sort-ghost',
            onUpdate: this.onSortUpdate1
          },
          fields: [
            { key: 'title', label: 'Merkmal'},
            { key: 'actions', label: '', tdClass: 'text-right'},
          ],
        },
        3: {
          value: 3,
          name: 'FAQs (Fragen & Antworten)',
          func: this.loadUsp3,
          id: 'loadUsp3',
          meta: {
            per_page: 9
          },
          usp: [],
          sortableOptions: {
            ghostClass: 'sort-ghost',
            onUpdate: this.onSortUpdate3
          },
          fields: [
            { key: 'title', label: 'Fragen und Antworten'},
            { key: 'actions', label: '', tdClass: 'text-right'},
          ],
        },
      },
      per_page_options: [
        { value: 9, text: '9' },
        { value: 15, text: '15' },
        { value: 30, text: '30' },
        { value: 99999, text: 'Alle' },
      ]
    }
  },
  methods: {
    loadUsp1(ctx, callback) {
      return this.loadUsp(ctx, callback, 1);
    },
    loadUsp3(ctx, callback) {
      return this.loadUsp(ctx, callback, 3);
    },
    loadUsp(ctx, callback, type) {
      let self = this;
      const params = '?page=' + ctx.currentPage + '&per_page=' + ctx.perPage;
      Http.request('GET', '/usp/' + type + params).then(function (usp) {
        self.types[type].meta = usp.meta;
        self.types[type].usp = usp.data;
        callback(usp.data)
      });
      return null
    },
    deleteUsp: function (usp, type) {
      let self = this;
      self.$bvModal.msgBoxConfirm('Möchtest du diesen Eintrag wirklich löschen?', {
          headerBgVariant: 'primary',
          title: 'Eintrag löschen?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if(value === true){
            Http.request('DELETE', '/usp/' + type  + '/' + usp.id).then(() => {
              if(
                typeof(self.$refs.loadUsp1) !== "undefined" &&
                typeof(self.$refs.loadUsp1.refresh) === "function"
              ){
                self.$refs.loadUsp1[0].refresh();
                self.$refs.loadUsp3[0].refresh();
              }
              else{
                self.$router.push('/data/usp');
              }
            });
          }
        });
    },
    onSortUpdate1: function (event) {
      this.saveSort(event, 1);
    },
    onSortUpdate3: function (event) {
      this.saveSort(event, 3);
    },
    saveSort (event, type) {
      this.types[type].usp.splice(event.newIndex, 0, this.types[type].usp.splice(event.oldIndex, 1)[0]);
      Http.request('POST', '/usp/' + type + '/sort', {data: this.types[type].usp, meta: this.types[type].meta});
    },
    setUspActive: function (usp, type) {
      this.$bvModal.msgBoxConfirm('Möchtest du diesen Eintrag ' + (usp.active ? 'offline' : 'online') + ' stellen?', {
          headerBgVariant: 'primary',
          title: 'Eintrag de/aktivieren?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(answer => {
          if(answer === true){
            let active = usp.active ? 0 : 1;
            Http.request('POST', '/usp/' + type + '/' + usp.id + '/active/' + active).then(() => {
              usp.active = active === 1;
            });
          }
        });
    },
  }
}

</script>

<style scoped></style>
